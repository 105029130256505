import React from "react"
import { graphql, Link } from "gatsby"
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from "../layouts"
import Seo from "../components/seo"
import {BreadCrumb, SectionPageTitle, SubTitle} from "../components/Section"
import BlogSection from "../sections/Home/BlogSection"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import LineArrowRight from "../components/Icons/LineArrowRight";

const HeroSection = styled.div`
	position: relative;
	padding: 50px 0 0;
`
const BackgroundWrap = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	.left-bg {
		width: 50%;
		height: 100%;
	}
	.right-bg {
		width: 50%;
		height: 100%;
	}
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	@media(max-width: 768px) {
		.left-bg {
			width: 100%;
		}
		.right-bg {
			display: none;
		}
	}
`
const OptionsContainer = styled.div`
	display: flex;
	border-top: 1px solid #D0D0D0;
	flex-wrap: wrap;

	.rto {
		width: 50%;
		padding: 44px 50px 100px 0;
		border-right: 1px solid #D0D0D0;
	}
	.financing {
		width: 50%;
		padding: 44px 0 100px 50px;
	}
	@media(max-width: 768px) {
		.rto {
			width: 100%;
			border-right: 0;
			padding: 30px 0 0;
			margin-bottom: 40px;
		}
		.financing {
			width: 100%;
			padding: 30px 0 0;
			margin-bottom: 40px;
		}
	}
`
const FeaturesWrap = styled.div`
	display: flex;
	flex-wrap: wrap;

	.feature {
		display: flex;
		align-items: center;
		width: 50%;
		margin-top: -40px;
		margin-left: -30px;
		padding-right: 20px;

		p {
			margin-bottom: 0;
		}
		@media(max-width: 1200px) {
			width: 100%;
		}
	}
`

const EasyPaymentOptions = ({ location, data }) => {
	const blogs = data.allContentfulBlog.edges
	const pageData = data.contentfulEasyPaymentOptionsPage
	return(
		<Layout footerCta location={location}>
			<Seo title={pageData.metaTitle} description={pageData.metaDescription} />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>{pageData.pageName}</span>
				</div>
			</BreadCrumb>
			<HeroSection>
				<BackgroundWrap>
					<div className="left-bg">
						<StaticImage
							src="../images/easy-rto.png"
			        placeholder="blurred"
			        formats={["AUTO", "WEBP", "AVIF"]}
			        alt="easy payment option rto"
			      />
					</div>
					<div className="right-bg">
						<StaticImage
							src="../images/easy-financing.png"
			        placeholder="blurred"
			        formats={["AUTO", "WEBP", "AVIF"]}
			        alt="easy payment option financing"
			      />
					</div>
				</BackgroundWrap>
				<div className="container">
					<SectionPageTitle pt="0" pb="0">
						{pageData.heroTitle}
					</SectionPageTitle>
					
					<OptionsContainer>
						<div className="rto">
							<SubTitle textAlign="left" mt="0" mb="10px">
								{pageData.paymentRto.title}
							</SubTitle>
							<div dangerouslySetInnerHTML={{__html: pageData.paymentRto.description.childMarkdownRemark.html}} />
							<FeaturesWrap>
								{
									pageData.paymentRto.features.map((item, i) => {
										return(
											<div className="feature">
												<img src={item.icon.file.url} alt={item.title} />
												<p className="title">{item.title}</p>
											</div>
										)
									})
								}
							</FeaturesWrap>
							<Link to={pageData.paymentRto.buttonUrl}>
								<PrimaryLinkButton text={pageData.paymentRto.buttonText} icon={<LineArrowRight />} />
							</Link>
						</div>
						<div className="financing">
							<SubTitle textAlign="left" mt="0" mb="10px">
								{pageData.paymentFinancing.title}
							</SubTitle>
							<div dangerouslySetInnerHTML={{__html: pageData.paymentFinancing.description.childMarkdownRemark.html}} />
							<FeaturesWrap>
								{
									pageData.paymentFinancing.features.map((item, i) => {
										return(
											<div className="feature">
												<img src={item.icon.file.url} alt={item.title} />
												<p className="title">{item.title}</p>
											</div>
										)
									})
								}
							</FeaturesWrap>
							<Link to={pageData.paymentFinancing.buttonUrl}>
								<PrimaryLinkButton text={pageData.paymentFinancing.buttonText} icon={<LineArrowRight />} />
							</Link>
						</div>
					</OptionsContainer>
				</div>
			</HeroSection>
			<BlogSection data={blogs} />
		</Layout>
	)
}

export default EasyPaymentOptions

export const query = graphql`
  query EasyPaymentOptionsPageQuery {
  	contentfulEasyPaymentOptionsPage {
  		pageName
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
      	childMarkdownRemark {
      		html
      	}
      }
      paymentRto {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          icon {
            file {
              url
            }
          }
        }
        buttonUrl
        buttonText
      }
      paymentFinancing {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        features {
          title
          icon {
            file {
              url
            }
          }
        }
        buttonUrl
        buttonText
      }
    }
	  allContentfulBlog(sort: {fields: createdAt, order: DESC}) {
	    edges {
	      node {
	        title
	        description {
	          childMarkdownRemark {
	            html
	          }
	        }
	        image {
	          gatsbyImageData(quality: 80)
	        }
	        author {
	          name
	        }
	        createdAt(formatString: "MMM DD, yy")
	        url
	      }
	    }
	  }
  }
`