import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import BlogCard from "../../components/BlogCard";
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton";
import LineArrowRight from "../../components/Icons/LineArrowRight";
import { Section, SectionTitle } from "../../components/Section";

const BlogList = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 56px 0 60px;

	.card {
		width: 32%;
	}

	@media(max-width: 991px) {
		display: block;
		.card {
			width: 100%;
			max-width: 500px;
			margin: auto;
			margin-bottom: 30px;
		}
	}
`

const BlogSection = ({ data }) => (
	<Section pt="80px" pb="80px" bgColor="#ffffff" textAlign="center">
		<div className="container">
			<SectionTitle textAlign="left">Our Blogs</SectionTitle>
			<BlogList>
			{
				data.map((item, i) => {
					if (i < 3) {
						return(
							<div className="card" key={`blog-${i}`}>
								<BlogCard data={item.node} />
							</div>
						)
					} else {
						return true;
					}
				})
			}
			</BlogList>
			<Link to="/blog">
				<PrimaryLinkButton text="Explore All Blogs" icon={<LineArrowRight />} />
			</Link>
		</div>
	</Section>
);

export default BlogSection